
.form_login{
    background-color:#FFFAFA;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;

}
.form_input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ef4342;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 67, 66 ,0.8);
}

.form_input{
    padding: 5px;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    width: 250px;

}
.btn_login{
    margin: 20px;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid transparent;
    color: #fff;
    background-color: #ef4342 !important;
    font-weight: 400;
    width: 250px;
}

.btn_login:disabled{
    background-color: #ea7481 !important;
}

.btn_login:hover:enabled{
    background-color:#DC3545 !important;
}

.login{
    padding-top: 20px;
}
