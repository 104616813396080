/*@import url("http://fonts.googleapis.com/css?family=Ubuntu:regular,bold&subset=Latin");*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /*font-family: "ubuntu", sans-serif;*/
}
:root {
    --blue: #e74c3c;
    --white: #ffffff;
    --grey: #f5f5f5;
    --black1: #222;
    --black2: #999;
}
body {
    min-height: 100vh;
    overflow: auto;
}
.container {
    position: relative;
    width: 100%;
}
.navigation
{
    position: fixed;
    width: 300px;
    height: 100%;
    background: var(--red);
    border-left: 10px solid var(--red);
    transition: 0.5s;
    overflow: hidden;

}
.navigation.active
{
    width: 80px;
}
.navigation ul
{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;

}
.navigation ul li
{
    position: relative;
    width: 100%;
    list-style: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.navigation ul li a.hovered
{
    position: relative;
    width: 100%;
    list-style: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.icon span svg {
    margin-bottom: 10px !important;
}

.navigation ul li a.hovered {
    background: var(--white);
    z-index: 0;
}

.navigation ul li a.hovered span {
    color: var(--red);
}

.navigation ul li a.hovered:hover {
    background: var(--white) !important;
    z-index: 0 !important;
}

.navigation ul li:hover {
    background: rgba(52, 58, 64, 0.36);
    z-index: 0;
}

.navigation ul li:nth-child(1) {
    margin-bottom: 40px;
    pointer-events: none;
}
.navigation ul li a {
    position: relative;
    /*display: block;*/
    width: 100%;
    display: flex;
    text-decoration: none;
    color: var(--white);
}
.navigation ul li.hovered a,
.navigation ul li:hover a {
    color: var(--white);
}

.navigation ul li a .icon {
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 65px;
    text-align: center;
    padding-top: 3px;
}

.navigation ul li a .icon ion-icon {
    font-size: 1.75em;
}
.navigation ul li a .title {
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 62px;
    text-align: start;
    white-space: nowrap;
}

.navigation ul li:hover a::before,
.navigation ul li.hovered a::before {
    content: "";
    position: absolute;
    right: 0;
    top: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 35px 35px 0 10px var(--white);
    pointer-events: none;
}

/*rgba(52, 58, 64, 0.36)*/

.navigation ul li:hover a::after,
.navigation ul li.hovered a::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 35px -35px 0 10px var(--white);
    pointer-events: none;
}

.navigation ul li.hovered:hover a::before {
    box-shadow: 35px 35px 0 10px var(--white);
}

.navigation ul li.hovered:hover a::after {
    box-shadow: 35px -35px 0 10px var(--white);
}

.navigation ul li:hover a::before {
    box-shadow: 35px 35px 0 10px transparent;
}

.navigation ul li:hover a::after {
    box-shadow: 35px -35px 0 10px transparent;
}

/* main*/
.main {
    position: absolute;

    width: calc(100% - 300px);
    left: 300px;
    min-height: 100vh;
    background: var(--white);
    transition: 0.5s;
}
.main.active {
    width: calc(100% - 80px);
    left: 80px;
}
.topbar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.toggle {
    position: relative;
    top: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*font-size: 2.5em;*/
    cursor: pointer;
}
.search {
    position: relative;
    width: 400px;
    margin: 0 10px;
}
.search label {
    position: relative;
    width: 100%;
}
.search label input {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    padding-left: 35px;
    outline: none;
    border: 1px solid var(--black2);
}
.search label .ion-icon {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 1.2em !important;
}

.user {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}
.user img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.msgBox {
    position: relative;
    width: 100%;
    padding: 20px;
}

.cardBox {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
}
.cardBox .card {
    position: relative;
    background: var(--white);
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
}
.cardBox .card .numbers {
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: var(--red);
}
.cardBox .card .cardName {
    color: var(--black2);
    font-size: 1.1em;
    margin-top: 5px;
}
.cardBox .card .iconBx {
    font-size: 3.5em;
    color: var(--black2);
}
.cardBox .card:hover {
    background: var(--red);
}

.cardBox .card:hover .numbers,
.cardBox .card:hover h4,
.cardBox .card:hover h5,
.cardBox .card:hover h6,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBx {
    color: var(--white);
}

.details {
    position: relative;
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
}

.details .recentOrders {
    position: relative;
    display: grid;
    min-height: 450px;
    background: var(--white);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.cardHeader h2 {
    font-weight: 600;
    color: var(--red);
}

.btn {
    position: relative;
    padding: 5px 10px;
    background: var(--red);
    text-decoration: none;
    color: var(--white);
    border-radius: 6px;
}

.details table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.details table thead td {
    font-weight: 600;
}

.details .recentOrders table tr {
    color: var(--black1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.details .recentOrders table tr:last-child {
    border-bottom: none;
}

.details .recentOrders table tr:hover {
    background: var(--red);
    color: var(--white);
}

.details .recentOrders table tr td {
    padding: 10px;
}

.details .recentOrders table tr td:last-child {
    text-align: end;
}

.details .recentOrders table tr td:nth-child(2) {
    text-align: end;
}

.details .recentOrders table tr td:nth-child(3) {
    text-align: center;
}

.status.delivered {
    padding: 2px;
    background: #8de02c;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.pending {
    padding: 2px;
    background: #f9ca3f;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.return {
    padding: 2px;
    background: #f00;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.In_progress {
    padding: 2px;
    background: #e74c3c;
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.recentCustomer {
    position: relative;
    display: grid;
    min-height: 500px;
    padding: 20px;
    background: var(--white);
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.recentCustomer .imgBx {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.recentCustomer .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recentCustomer table tr td {
    padding: 12px 10px;
}
.recentCustomer table tr td h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2em;
}
.recentCustomer table tr td h4 span {
    font-size: 14px;
    color: var(--black2);
}
.recentCustomer table tr:hover {
    background: var(--red);
    color: var(--white);
}
.recentCustomer table tr:hover td h4 span {
    color: var(--white);
}

/*  responsive design*/
@media (max-width: 991px) {
    .navigation {
        left: -300px;
    }
    .main {
        width: 100%;
        left: 0;
    }
    .main.active {
        left: 300px;
    }

    .cardBox {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 768px) {
    .details {
        grid-template-columns: repeat(1, 1fr);
    }
    .recentOrders {
        overflow-x: auto;
    }
    .status.In_progress {
        white-space: nowrap;
    }
}

@media (max-width: 768px) {
    .cardBox {
        grid-template-columns: repeat(1, 1fr);
    }
    .cardHeader h2 {
        font-size: 20px;
    }
    .user {
        min-width: 40px;
    }
    .navigation {
        width: 100%;
        left: 100%;
        z-index: 1000;
    }
    .navigation.active {
        width: 100%;
        left: 0;
    }
    .toggle {
        z-index: 10001;
    }
    main.active .toggle {
        color: var(--white);
        position: fixed;
        right: 0;
        left: initial;
    }
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: 0.5;
}


.placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
    50% {
        opacity: 0.2;
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: 0.2;
    }
}