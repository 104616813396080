.my-card {
    background: #2ECC71 !important;
}

.my-card .cardName {
    color: white !important;
}

.my-card:hover {
    background: #27AE60 !important;
}